import React from "react";
import "./Footer.css";

export default () => {
  return (
    <footer>
      <p>© 2021 Jason Taylor</p>
    </footer>
  );
};
